<template>
    <div class="MyIntegral">
        <div class="title-card">
            <img src="@/assets/jf.png" class="img" alt="" />
            <div class="text">我的积分</div>
        </div>
        <Ranking
            :rankingData="rankingData"
            :record="record"
            :myIntegral="myIntegral"
            :myRanking="myRanking"
        ></Ranking>
    </div>
</template>

<script>
    import Ranking from "@/components/Ranking.vue";
    export default {
        name: "MyIntegral",
        components: {
            Ranking,
        },

        data() {
            return {
                rankingData: [], //积分列表
                myIntegral: 0, //我的积分
                record: [], //积分记录
                myRanking: 0, //我的排行
            };
        },

        mounted() {
            window.localStorage.setItem("myNavActive", 0);
            this.$store.commit("switchMyNav", 0);
            this.getIntegral();
            this.getIntegralRanking();
        },

        methods: {
            //积分记录
            getIntegral() {
                this.$https.get("/api/credit_log/list").then((res) => {
                    this.myIntegral = res.data.total_credit;
                    this.record = res.data.list;
                });
            },

            //积分排行
            getIntegralRanking() {
                this.$https
                    .get("/api/credit_log/ranking", { page: 1, limit: 10 })
                    .then((res) => {
                        this.myIntegral = res.data.total_credit;
                        this.rankingData = res.data.list;
                        this.myRanking = res.data.self_rank;
                    });
            },
        },
    };
</script>

<style lang="less" scoped>
.MyIntegral {
    width: 100%;
    .title-card {
        width: 100%;
        background: #ffffff;
        border-radius: 6px;
        padding: 22px 19px;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .img {
            width: 34px;
            height: 28px;
        }
        .text {
            font-size: 18px;
            font-weight: 500;
            color: #2d2d2d;
            margin-left: 12px;
        }
    }
}
</style>